import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthView from '../views/AuthView.vue'
import RegisterView from '../views/RegisterView.vue'
import RestoreView from '../views/RestoreView.vue'
import UserInfoView from '../views/UserInfoView.vue'
import SupportView from '../views/SupportView.vue'

Vue.use(VueRouter)

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    name: "AuthView",
    component: AuthView,
    meta: {
      requiresAuth: false,
      title: "Вход в систему"
    }
  },
  {
    path: "/register",
    name: "RegisterView",
    component: RegisterView,
    meta: {
      requiresAuth: false,
      title: "Регистрация"
    }
  },
  {
    path: "/restore",
    name: "RestoreView",
    component: RestoreView,
    meta: {
      requiresAuth: false,
      title: "Восстановление пароля"
    }
  },
  {
    path: "/userinfo",
    name: "UserInfoView",
    component: UserInfoView,
    meta: {
      requiresAuth: false,
      title: "Информация о пользователе"
    }
  },
  {
    path: "/support",
    name: "SupportView",
    component: SupportView,
    meta: {
      requiresAuth: false,
      title: "Восстановление доступа"
    }
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
