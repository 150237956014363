<template>
  <Wrapper :appName="appName" :title="'Восстановление пароля'">
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="error"
      v-show="notValidated"
    >
      Аккаунт не найден, или автоматическое восстановление доступа для него
      невозможно.
    </v-alert>

    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="error"
      v-show="wrongAnswer"
    >
      Неверный ответ на секретный вопрос, автоматическое восстановление доступа
      невозможно.
    </v-alert>

    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="error"
      v-show="emailcodeWrong"
    >
      Неверный код, автоматическое восстановление доступа невозможно.
    </v-alert>

    <v-alert outlined type="success" text v-if="isCompleted">
      Пароль успешно изменен. Вы можете войти в аккаунт.
    </v-alert>

    <div class="auth__input mb-5 rounded">
      <input
        v-model="loginemail"
        name="loginemail"
        type="test"
        placeholder="E-mail или логин"
        :class="[step !== 1 ? 'auth__button_disabled_text' : '']"
        :disabled="step !== 1"
      />
    </div>

    <div
      class="auth__input mb-5 rounded"
      :class="[step !== 2 ? 'disabled' : '']"
      v-show="step === 2"
    >
      <input
        placeholder="Вопрос"
        name="secreta"
        type="text"
        v-model="secreta"
        :disabled="step !== 2"
      />
    </div>

    <div
      class="auth__input mb-5 rounded"
      :class="[step !== 3 ? 'disabled' : '']"
      v-show="step === 3"
    >
      <input
        placeholder="Код, полученный на почту"
        name="emailcode"
        type="text"
        v-model="emailcode"
        :disabled="step !== 3"
      />
    </div>

    <div v-if="step === 4">
      <div class="auth__input auth__two_params rounded">
        <input
          v-model="password"
          name="password"
          v-if="!show1"
          placeholder="Пароль"
          type="password"
        />
        <input
          v-model="password"
          name="password"
          v-else
          placeholder="Пароль"
          type="text"
        />
        <button v-on:click="switchVisibleIcon">
          <v-icon>{{ show1 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
        </button>
      </div>
    </div>

    <v-btn
      color="secondary"
      v-on:click="goToSupport"
      v-show="notValidated || wrongAnswer || emailcodeWrong"
      x-small
    >
      Обратиться в службу технической поддержки
    </v-btn>

    <button
      class="auth__button auth__transition mt-5 rounded title font-weight-bold"
      v-on:click="pefrormRestore"
    >
      Далее
    </button>
  </Wrapper>
</template>

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";
import Wrapper from "./Wrapper.vue";

export default {
  components: {
    Wrapper,
  },
  props: {
    user: Object,
    appName: String,
  },
  name: "Restore",
  data: () => ({
    loginemail: "",
    step: 1,
    notValidated: false,
    isLoginInProcess: false,
    secretq: "",
    secreta: "",
    wrongAnswer: false,
    emailcode: "",
    emailcodeWrong: false,
    newpassword: "",
    newpassword2: "",
    show1: false,
    show2: false,
    isCompleted: false,
    tmppassword: "",
    rules: {
      required: (value) => !!value || "Обязательно к заполнению",
      min: (v) => v.length >= 8 || "Не менее 8 символов",
      isUpperCase: (v) => /[A-Z]/.test(v) || "Не содержит заглавные буквы",
      isLowerCase: (v) => /[a-z]/.test(v) || "Не содержит строчные буквы",
      isNumbers: (v) => /[0-9]/.test(v) || "Не содержит цифры",
      isSpecChars: (v) => /[#?!@$%^&*-]/.test(v) || "Не содержит спецсимволы",
      email: (v) => /\S+@\S+\.\S+/.test(v) || "Некорректный адрес",
    },
  }),

  computed: {
    disableLoginButton() {
      return (
        this.token ||
        this.isLoginInProcess ||
        !this.loginemail ||
        (this.step === 2 && !this.secreta) ||
        (this.step === 3 && !this.emailcode) ||
        (this.step === 4 && !this.newpassword) ||
        (this.step === 4 && this.newpassword !== this.newpassword2)
      );
    },
  },

  created() {
    let self = this;
    if (
      this.$route.params.restoreUsername &&
      this.$route.params.restoreTmpPassword
    ) {
      self.loginemail = this.$route.params.restoreUsername;
      self.tmppassword = this.$route.params.restoreTmpPassword;
      self.step = 4;
    }
  },

  methods: {
    goToSupport(e) {
      if (e) e.preventDefault();
      this.$router.push("/support");
    },
    goToLogin(e) {
      if (e) e.preventDefault();
      this.$router.push("/login");
    },
    pefrormRestore(e) {
      if (e) e.preventDefault();
      let self = this;

      if (self.step === 1 && !self.isLoginInProcess && self.loginemail) {
        self.notValidated = false;
        self.isLoginInProcess = true;
        self.secretq = "";
        axios({
          url: endpointUrl + "restoreByLoginOrEmail",
          method: "POST",
          data: {
            loginemail: self.loginemail,
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          if (res && res.data && res.data.status === "validated") {
            self.secretq = res.data.secretq;
            self.step++;
          } else {
            self.notValidated = true;
          }
        });
      }

      if (self.step === 2 && !self.isLoginInProcess && self.secreta) {
        self.wrongAnswer = false;
        self.isLoginInProcess = true;
        axios({
          url: endpointUrl + "restoreByLoginOrEmail",
          method: "POST",
          data: {
            loginemail: self.loginemail,
            secreta: self.secreta,
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          if (res && res.data && res.data.status === "code sent") {
            self.emailcodeWrong = false;
            self.emailcode = "";
            self.step++;
          } else {
            self.wrongAnswer = true;
          }
        });
      }

      if (self.step === 3 && !self.isLoginInProcess && self.emailcode) {
        self.wrongAnswer = false;
        self.isLoginInProcess = true;
        axios({
          url: endpointUrl + "restoreByLoginOrEmail",
          method: "POST",
          data: {
            loginemail: self.loginemail,
            secreta: self.secreta,
            emailcode: self.emailcode,
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          if (res && res.data && res.data.status === "code accepted") {
            self.emailcodeWrong = false;
            self.newpassword = "";
            self.newpassword2 = "";
            self.step++;
          } else {
            self.emailcodeWrong = true;
          }
        });
      }

      if (
        self.step === 4 &&
        !self.isLoginInProcess &&
        self.newpassword &&
        self.newpassword === self.newpassword2 &&
        self.$refs.restoreform.validate()
      ) {
        self.wrongAnswer = false;
        self.isLoginInProcess = true;
        axios({
          url: endpointUrl + "restoreByLoginOrEmail",
          method: "POST",
          data: {
            loginemail: self.loginemail,
            secreta: self.secreta,
            emailcode: self.emailcode,
            newpassword: self.newpassword,
            tmppassword: self.tmppassword,
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          if (res && res.data && res.data.status === "ok") {
            self.secreta = "";
            self.emailcode = "";
            self.newpassword = "";
            self.newpassword2 = "";
            self.step++;
            self.isCompleted = true;
          } else {
            alert("Возникла ошибка при изменении пароля");
          }
        });
      }

      if (self.step === 5) {
        this.$router.push("/login");
      }
    },
  },
};
</script>
